import React, { useState, useRef } from 'react'
import { any, bool, objectOf, string } from 'prop-types'
import { useDispatch } from 'react-redux'
import { ClickAwayListener } from '@mui/base'
import { Button, styled } from '@mui/material'
import DeliveryDateModal from '@shared/modals/DatePicker'
import { months, weekdays } from '@helpers/string-helper'
import { updateDelivery } from '@services/checkout'
import { getDeliverySpecificBody } from '@helpers/checkout/delivery-section'
import { setOrder } from '@redux/modules/checkout'
import { updateDeliveryAnalytics } from '@helpers/google-tag-manager'

const StyledButtonAsLink = styled(Button, { shouldForwardProp: prop => prop !== 'isMobile' })(({ isMobile }) => ({
  fontSize: isMobile ? '12px' : '14px',
  fontWeight: 400,
  textTransform: 'capitalize',
  textDecoration: 'underline solid',
  textUnderlineOffset: '2px',
  padding: 0,
}))

export const CalendarPopupSplit = ({
  index,
  initialDate,
  isMobile,
  order,
  shipment,
  shipmentsData,
  testId,
  text = 'Change Delivery Date',
}) => {
  const dispatch = useDispatch()
  const [shipmentModalVisibility, setShipmentModalVisibility] = useState(false)

  const updateShipmentDeliveryDate = async date => {
    if (order) {
      const splitDelivery = order.splitDeliveryDates.map((item, i) => (i === index ? date.date : item.deliveryDate))
      const updatedOrder = { ...order, splitDelivery }

      try {
        const data = await updateDelivery(getDeliverySpecificBody(updatedOrder, updatedOrder?.deliveryMode))
        dispatch(setOrder(data))
        setShipmentModalVisibility(false)
        updateDeliveryAnalytics(data)
      } catch {
        console.error('Update Delivery Modal Failure')
      }
    }

    return null
  }

  const handleOpenModal = e => {
    if (e?.type === 'click' || e?.code === 'Enter') {
      e.preventDefault()
      setShipmentModalVisibility(true)
    }
  }

  const handleCloseModal = e => {
    e.stopPropagation()
    setShipmentModalVisibility(false)
  }

  const getDeliveryDates = isPickup => {
    const dateFormatter = dateString => {
      const newDate = new Date(dateString)
      return {
        date: dateString,
        readable: `${months[newDate.getUTCMonth()]} ${newDate.getUTCDate()}`,
        dayOfWeek: weekdays[newDate.getUTCDay()].substring(0, 3),
      }
    }

    const { deliveryCalendar } = shipmentsData?.[index]?.data
    if (!isPickup) {
      /* premium delivery or doorway delivery dates with potential express dates prepended to the front of a new array */
      if (deliveryCalendar?.expressDeliveryDates?.length > 0) {
        return [
          ...deliveryCalendar?.expressDeliveryDates
            ?.map(dateFormatter)
            ?.map(date => ({ ...date, isExpressDelivery: true })),
          ...deliveryCalendar?.deliveryDates?.map(dateFormatter),
        ]
      }
      return deliveryCalendar?.deliveryDates?.map(dateFormatter)
    }

    /* pickup delivery dates */
    return deliveryCalendar?.pickupDates?.map(dateFormatter)
  }
  const popupPositionalRef = useRef()
  return (
    <ClickAwayListener onClickAway={handleCloseModal}>
      {/* set a reference to the parent div so we can place the popup in the correct position */}
      <>
        <StyledButtonAsLink
          data-testid={testId}
          isMobile={isMobile}
          onClick={handleOpenModal}
          onKeyDown={handleOpenModal}
          value="Select Delivery Date"
          ref={popupPositionalRef}
        >
          {text}
        </StyledButtonAsLink>

        {shipmentModalVisibility && (
          <DeliveryDateModal
            buttonText={text}
            closeModal={handleCloseModal}
            currentRef={popupPositionalRef} // pass in the div ref
            dateRange={getDeliveryDates(order.isPickup)}
            initialDate={{
              date: initialDate,
              readable: `${
                months[shipment.deliveryDateObject.getUTCMonth()]
              } ${shipment.deliveryDateObject.getUTCDate()}`,
            }}
            isOpen={shipmentModalVisibility}
            isPickup={order.isPickup}
            onSubmit={updateShipmentDeliveryDate}
          />
        )}
      </>
    </ClickAwayListener>
  )
}

CalendarPopupSplit.propTypes = {
  index: any,
  initialDate: string,
  isMobile: bool,
  order: objectOf(any),
  shipment: any,
  shipmentsData: any,
  testId: string,
  text: string,
}

export default CalendarPopupSplit
