import React, { useState, useRef } from 'react'
import { any, bool, objectOf, string } from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { ClickAwayListener } from '@mui/base'
import { Button, styled } from '@mui/material'
import DeliveryDateModal from '@shared/modals/DatePicker'
import { months, weekdays } from '@helpers/string-helper'
import { updateDelivery } from '@services/checkout'
import { getDeliverySpecificBody } from '@helpers/checkout/delivery-section'
import { setOrder } from '@redux/modules/checkout'
import { updateDeliveryAnalytics } from '@helpers/google-tag-manager'

const StyledButtonAsLink = styled(Button, { shouldForwardProp: prop => prop !== 'isMobile' })(({ isMobile }) => ({
  fontSize: isMobile ? '12px' : '14px',
  fontWeight: 400,
  textTransform: 'capitalize',
  textDecoration: 'underline solid',
  textUnderlineOffset: '2px',
  padding: 0,
}))

export const CalendarPopup = ({ initialDate, isMobile, order, testId, text = 'Change Delivery Date' }) => {
  const dispatch = useDispatch()
  const deliveryMode = useSelector(state => state?.checkout?.deliveryMode)
  const [modalVisibility, setModalVisibility] = useState(false)
  const popupPositionalRef = useRef()

  const updateDeliveryDate = async date => {
    if (order) {
      const updatedOrder = { ...order, deliveryDate: date.date }

      try {
        const data = await updateDelivery(getDeliverySpecificBody(updatedOrder, deliveryMode || order?.deliveryMode))
        dispatch(setOrder(data))
        setModalVisibility(false)
        updateDeliveryAnalytics(data)
      } catch {
        return console.error('Update Delivery Modal Failure')
      }
    }

    return null
  }

  const handleOpenModal = e => {
    if (e?.type === 'click' || e?.code === 'Enter') {
      e.preventDefault()
      setModalVisibility(true)
    }
  }

  const handleCloseModal = e => {
    e.stopPropagation()
    setModalVisibility(false)
  }

  const getDeliveryDates = isPickup => {
    const dateFormatter = dateString => {
      const newDate = new Date(dateString)
      return {
        date: dateString,
        readable: `${months[newDate.getUTCMonth()]} ${newDate.getUTCDate()}`,
        dayOfWeek: weekdays[newDate.getUTCDay()].substring(0, 3),
      }
    }

    if (!isPickup) {
      /* premium delivery dates with potential express dates prepended to the front of a new array */
      if (order?.expressCalendar?.length > 0) {
        return [
          ...order?.expressCalendar?.map(dateFormatter)?.map(date => ({ ...date, isExpressDelivery: true })),
          ...order?.deliveryCalendar?.map(dateFormatter),
        ]
      }
      return order?.deliveryCalendar?.map(dateFormatter)
    }
    /* pickup delivery dates */
    return order?.pickupCalendar?.map(dateFormatter)
  }
  const deliveryDateObject = new Date(order?.deliveryDate)

  const dateRange = getDeliveryDates(order.isPickup)
  if (!dateRange) return null

  return (
    <ClickAwayListener onClickAway={handleCloseModal}>
      {/* set a reference to the parent div so we can place the popup in the correct position */}
      <>
        <StyledButtonAsLink
          data-testid={testId}
          // disabled={order?.lineItems?.filter(item => item.deliveryType === 'S').length > 0}
          isMobile={isMobile}
          onClick={handleOpenModal}
          onKeyDown={handleOpenModal}
          value="Select Delivery Date"
          ref={popupPositionalRef}
        >
          {text}
        </StyledButtonAsLink>

        {modalVisibility && (
          <DeliveryDateModal
            buttonText={text}
            closeModal={handleCloseModal}
            currentRef={popupPositionalRef} // pass in the div ref
            dateRange={dateRange}
            initialDate={{
              date: initialDate,
              readable: `${months[deliveryDateObject.getUTCMonth()]} ${deliveryDateObject.getUTCDate()}`,
            }}
            isOpen={modalVisibility}
            isPickup={order.isPickup}
            onSubmit={updateDeliveryDate}
          />
        )}
      </>
    </ClickAwayListener>
  )
}

CalendarPopup.propTypes = {
  initialDate: string,
  isMobile: bool,
  order: objectOf(any),
  testId: string,
  text: string,
}

export default CalendarPopup
