import React, { useLayoutEffect, useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useQuery } from 'react-query'
import { CircularProgress } from '@material-ui/core'
import { Button, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import { updatePayment } from '@services/checkout'
import { setCheckoutStep, setOrder } from '@redux/modules/checkout'
import { getSessionItem, setSessionItem } from '@helpers/checkout/checkout'
import { checkoutStepAnalytics } from '@helpers/google-tag-manager'
import { DialogDivider } from './styles'

//  React-queries
function useKlarnaPayment({ orderId, isDirty }) {
  return useQuery(
    ['klarna_token', orderId, isDirty],
    () =>
      axios.get(`${process.env.GATSBY_KLARNA_URL}/token/order/${orderId}`).then(el => {
        const { data } = el
        const { client_token, session_id, klarnaOrder } = data
        setSessionItem('klarna_auth_total', klarnaOrder.order_amount)
        setSessionItem('klarna_session_order', klarnaOrder)
        setSessionItem('klarna_session_id', session_id)
        setSessionItem('klarna_session_token', client_token)
        return {
          session_id,
          klarnaOrder,
        }
      }),
    {
      enabled: isDirty,
      refetchOnWindowFocus: false,
    },
  )
}

export default function KlarnaPayment({ orderId, total, onClose }) {
  const [klarnaReady, setKlarnaReady] = useState(false)
  const sessionToken = getSessionItem('klarna_session_token')
  const sessionAuth_Total = getSessionItem('klarna_auth_total')
  const sessionKlarnaOrder = getSessionItem('klarna_session_order')
  const isDirty = sessionAuth_Total !== total.toString().replace('.', '')
  const dispatch = useDispatch()
  const existingPaymentInfo = useSelector(state => state.checkout.order.paymentInfo)

  //  React-Query
  const { data, isLoading, error, isIdle } = useKlarnaPayment({
    orderId,
    isDirty,
  })

  const klarnaOrder = useMemo(() => data?.klarnaOrder || sessionKlarnaOrder, [data, sessionKlarnaOrder])
  useLayoutEffect(() => {
    if (sessionToken) {
      try {
        window.Klarna.Payments.init({
          client_token: sessionToken,
        })
        window.Klarna.Payments.load(
          {
            container: `#klarna-payments-container`,
            payment_method_category: 'pay_later',
            show_form: true,
          },
          res => {
            setKlarnaReady(true)
          },
        )
      } catch (e) {
        // Handle error.
        console.error('error initiating klarna', e)
      }
    }
  }, [sessionToken])

  const authorizeKlarna = authToken => {
    updatePayment({
      orderId,
      paymentInfo: [
        ...existingPaymentInfo,
        {
          paymentType: 'KLN',
          authorizedAmount: total,
          paymentProperties: {
            authToken,
            orderId,
          },
        },
        // {
        //   ****  GIFT CARD EXAMPLE ****
        //   paymentType: 'GIFT',
        //   authorizedAmount: 1000.0,
        //   paymentProperties: {
        //     cardNumber: '7777229957651062',
        //     pin: '81883003',
        //   },
        // },
      ],
    })
      .then(updatedOrder => {
        checkoutStepAnalytics('review')
        dispatch(setCheckoutStep('review'))
        dispatch(setOrder(updatedOrder))
      })
      .catch(err => {
        console.error('error authorizing klarna', err)
      })
  }

  const handleClick = () => {
    window.Klarna.Payments.authorize({ payment_method_category: 'pay_later' }, { ...klarnaOrder }, res => {
      if (res.approved) {
        authorizeKlarna(res.authorization_token)
      }
      //  Returned error or user cancelled
    })
  }

  if (error) {
    if (error?.response) {
      let errorStr = ''
      if (error.response.data.error.message.indexOf('given_name') > -1) {
        errorStr = 'First name'
      }
      if (error.response.data.error.message.indexOf('family_name') > -1) {
        if (errorStr.length) {
          errorStr += ', Last name'
        } else {
          errorStr = 'Last name'
        }
      }
      return (
        <>
          <DialogTitle
            id="payment-modal-synchrony__title"
            sx={{ marginTop: '30px', fontWeight: 'bold', textTransform: 'none' }}
          >
            There has been an error with your address
            <IconButton
              aria-label="close"
              onClick={onClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <p>Please check the following fields:</p>
            <p>{errorStr}</p>
            <Button
              type="button"
              variant="contained"
              fullWidth
              sx={{
                backgroundColor: 'secondary.dark',
                color: 'secondary.light',
                marginTop: '5px',
                '&:hover': {
                  color: 'secondary.light',
                  backgroundColor: 'secondary.dark',
                },
              }}
              onClick={() => {
                onClose()
              }}
            >
              <div className="mdc-button__ripple" />
              <span className="mdc-button__label">Close</span>
            </Button>
          </DialogContent>
        </>
      )
    }
    return (
      <>
        <DialogContent>
          <p style={{ color: '#eb141f' }}>
            Something went wrong initializing Klarna Payments, please try again later...
          </p>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center', padding: '12px' }}>
          <Button fullWidth variant="text" style={{ maxWidth: '45%' }} onClick={!isLoading ? onClose : null}>
            Close
          </Button>
        </DialogActions>
      </>
    )
  }

  if (isLoading) {
    return (
      <div style={{ overflow: 'hidden' }}>
        <CircularProgress />
      </div>
    )
  }

  if (sessionToken) {
    return (
      <>
        <DialogTitle id="payment-modal-synchrony__title" sx={{ fontWeight: 'bold' }}>
          Select Klarna Financing Plan
        </DialogTitle>
        <DialogContent>
          <div id="klarna-payments-container" />
        </DialogContent>
        <DialogDivider />
        <DialogActions sx={{ justifyContent: 'center', padding: '24px' }}>
          <Button fullWidth variant="outlined" style={{ maxWidth: '45%' }} onClick={!isLoading ? onClose : null}>
            Cancel
          </Button>
          <Button
            sx={{ height: '40px' }}
            disabled={!klarnaReady}
            fullWidth
            variant="contained"
            onClick={!isLoading ? handleClick : null}
          >
            {!isLoading && 'Submit'}
          </Button>
        </DialogActions>
      </>
    )
  }
  return null
}

KlarnaPayment.propTypes = {
  orderId: PropTypes.string.isRequired,
  total: PropTypes.number.isRequired,
  onClose: PropTypes.func,
}
